import React from 'react';

import { SunDialProps } from './dial/types';
import DialBackground from './dial/DialBackground';
import DialOffsetText from './dial/DialOffsetText';
import DialPoints from './dial/DialPoints';
import DialRing from './dial/DialRing';

function SunDial(props: SunDialProps) {
  return (
    <svg
      viewBox="0 0 1000 1000"
      width="400"
      height="400"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <DialBackground offset={props.offset}></DialBackground>
      <DialRing></DialRing>
      <DialPoints offset={props.offset}></DialPoints>
      <DialOffsetText offset={props.offset}></DialOffsetText>
    </svg>
  );
}

export default SunDial;
