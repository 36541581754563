import React from 'react';

import { polarToXY } from './utils';

import './DialRing.scss';

function buildDashArray(radius: number, parts: number, inter: number): string {
  const circumference = 2 * Math.PI * radius;
  const slice = circumference / (parts * (1 + inter));
  return `${slice} ${slice * inter}`;
}

function HoursNumbers() {
  const hours = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24];
  const radius = 345;
  return (
    <>
      {hours.map((hour) => {
        const { x, y } = polarToXY(radius, hour / 24.0, 500, 510);
        return (
          <text key={hour} x={x} y={y} textAnchor="middle" className="dial-ring-number">
            {String(hour).padStart(2, '0')}
          </text>
        );
      })}
    </>
  );
}

function DialRing(props: {}) {
  return (
    <>
      <circle
        cx="500"
        cy="500"
        r={400}
        className="dial-ring-decaminute"
        strokeDasharray={buildDashArray(400, 24 * 6, 3)}
      ></circle>
      <circle
        cx="500"
        cy="500"
        r={390}
        className="dial-ring-hour"
        strokeDasharray={buildDashArray(390, 24, 3 * 6)}
      ></circle>
      <HoursNumbers></HoursNumbers>
    </>
  );
}

export default DialRing;
