import React, { useState } from 'react';

import SunDial from './SunDial';

const WHEEL_MODE_PIXEL_MULTIPLIER = {
  [WheelEvent.DOM_DELTA_PIXEL]: 1,
  [WheelEvent.DOM_DELTA_LINE]: 18,
  [WheelEvent.DOM_DELTA_PAGE]: 600,
};

function SunScroll() {
  const [position, setPosition] = useState<number>(0.0);
  const [touchStartY, setTouchStartY] = useState<number>(0.0);
  const [touchDiffY, setTouchDiffY] = useState<number>(0.0);

  function calculate() {
    return position + touchDiffY * 20;
  }

  function handleWheel(event: React.WheelEvent<HTMLDivElement>) {
    const multiplier = WHEEL_MODE_PIXEL_MULTIPLIER[event.deltaMode];
    setPosition(position + event.deltaY * multiplier);
  }

  function handleTouchStart(event: React.TouchEvent<HTMLDivElement>) {
    setTouchStartY(event.touches[0].screenY);
  }

  function handleTouchMove(event: React.TouchEvent<HTMLDivElement>) {
    setTouchDiffY(event.touches[0].screenY - touchStartY);
  }

  function handleTouchEnd(event: React.TouchEvent<HTMLDivElement>) {
    setPosition(calculate());
    setTouchStartY(0);
    setTouchDiffY(0);
  }
  return (
    <div
      onWheel={handleWheel}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <SunDial offset={calculate() / 5300}></SunDial>
    </div>
  );
}

export default SunScroll;
