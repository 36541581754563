import React from 'react';

import { calculateSolarTimes } from '../../logic/calculate';
import { SunDialProps } from './types';
import { polarToCoords } from './utils';

import './DialBackground.scss';

export interface Slice {
  kind: string;
  angleFrom: number;
  angleTo: number;
}

export function nightSlices(times: ReturnType<typeof calculateSolarTimes>): Slice[] {
  const stages: { kind: string; dawn: number; dusk: number }[] = [
    { kind: 'civil', dawn: times.sunrise, dusk: times.sunset },
    { kind: 'nautical', dawn: times.civilDawn, dusk: times.civilDusk },
    { kind: 'astronomical', dawn: times.nauticalDawn, dusk: times.nauticalDusk },
    { kind: 'night', dawn: times.astronomicalDawn, dusk: times.astronomicalDusk },
  ].filter((stage) => !isNaN(stage.dawn) && !isNaN(stage.dusk));

  const result: Slice[] = [];
  for (let i = 0; i < stages.length - 1; i++) {
    const stageOut = stages[i];
    const stageIn = stages[i + 1];
    result.push({ kind: stageOut.kind, angleFrom: stageIn.dawn, angleTo: stageOut.dawn });
    result.push({ kind: stageOut.kind, angleFrom: stageOut.dusk, angleTo: stageIn.dusk });
  }
  if (stages.length > 0) {
    const slice = stages[stages.length - 1];
    result.push({ kind: slice.kind, angleFrom: slice.dusk, angleTo: slice.dawn });
  }

  return result;
}

function buildPath(slice: Slice): string {
  const coordsFrom = polarToCoords(450, slice.angleFrom);
  const coordsTo = polarToCoords(450, slice.angleTo);
  return `M 500,500 L ${coordsFrom} A 450,450 0,0,1 ${coordsTo} Z`;
}

function DialBackground(props: SunDialProps) {
  const date = new Date();
  date.setDate(date.getDate() + Math.floor(props.offset));
  const times = calculateSolarTimes(date, { latitude: 52.16, longitude: 21.02 });

  return (
    <>
      <circle cx="500" cy="500" r={450} fill="#33d" />
      {nightSlices(times).map((slice, idx) => (
        <path key={idx} d={buildPath(slice)} className={`slice-${slice.kind}`} />
      ))}
    </>
  );
}

export default DialBackground;
