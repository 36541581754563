export function polarToXY(
  radius: number,
  angle: number,
  centerX: number = 500,
  centerY: number = 500,
): { x: number; y: number } {
  const rad = angle * 2 * Math.PI;
  return {
    x: centerX + Math.sin(rad) * -radius,
    y: centerY + Math.cos(rad) * radius,
  };
}

export function polarToCoords(
  radius: number,
  angle: number,
  centerX: number = 500,
  centerY: number = 500,
): string {
  const { x, y } = polarToXY(radius, angle, centerX, centerY);
  return `${x},${y}`;
}
