import SunCalc from 'suncalc';

import { LocationType, SolarTimesType } from './types';

export function toOffset(date: Date): number {
  const seconds = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  return seconds / 86400.0;
}

export function calculateSolarTimes(date: Date, location: LocationType): SolarTimesType {
  const times = SunCalc.getTimes(date, location.latitude, location.longitude);
  return {
    solarNoon: toOffset(times.solarNoon),
    solarMidnight: toOffset(times.nadir),
    sunrise: toOffset(times.sunrise),
    sunset: toOffset(times.sunset),
    civilDawn: toOffset(times.dawn),
    civilDusk: toOffset(times.dusk),
    nauticalDawn: toOffset(times.nauticalDawn),
    nauticalDusk: toOffset(times.nauticalDusk),
    astronomicalDusk: toOffset(times.night),
    astronomicalDawn: toOffset(times.nightEnd),
  };
}
