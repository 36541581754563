import React from 'react';

import { SunDialProps } from './types';

import './DialOffsetText.scss';

function formatTime(offset: number): string {
  function format(value: number): string {
    return Math.floor(value).toFixed(0).padStart(2, '0');
  }
  const sign = offset < 0 ? '-' : '+';
  const days = Math.floor(Math.abs(offset));
  const seconds = (Math.abs(offset) % 1.0) * 86400;
  const hours = format(seconds / 3600);
  const minutes = format((seconds / 60) % 60);
  return `${sign}${days}d ${hours}:${minutes}`;
}

function DialOffsetText(props: SunDialProps) {
  return (
    <text x="500" y="510" textAnchor="middle" className="dial-offset-text">
      {formatTime(props.offset)}
    </text>
  );
}

export default DialOffsetText;
