import React from 'react';

import { calculateSolarTimes, toOffset } from '../../logic/calculate';
import { SunDialProps } from './types';
import { polarToXY, polarToCoords } from './utils';

import './DialPoints.scss';

function DialPoints(props: SunDialProps) {
  const date = new Date();
  date.setDate(date.getDate() + Math.floor(props.offset));
  const times = calculateSolarTimes(date, { latitude: 52.16, longitude: 21.02 });
  const points = [
    times.sunrise,
    times.sunset,
    times.solarNoon,
    times.solarMidnight,
    times.civilDawn,
    times.civilDusk,
    times.nauticalDawn,
    times.nauticalDusk,
    times.astronomicalDawn,
    times.astronomicalDusk,
  ];
  const nowOffset = toOffset(new Date());
  const nowPoint = polarToXY(450, nowOffset);

  const path = (nowOffset: number, scrollOffset: number): string => {
    const idx = Math.floor(((scrollOffset % 1.0) + 1.0) / 0.5);
    const params = ['0,1,0', '0,0,0', '0,0,1', '0,1,1'][Math.floor(idx)];
    const off = scrollOffset < 0 ? -(-scrollOffset % 1.0) : scrollOffset % 1.0;
    const coordsFrom = polarToCoords(300, nowOffset);
    const coordsTo = polarToCoords(300, off + nowOffset);
    return ` ${coordsFrom} A 300,300 ${params} ${coordsTo}`;
  };

  const offsetPath = path(nowOffset, props.offset);

  return (
    <>
      <circle cx="500" cy="500" r={300} className="dial-points-base" />
      <line x1="500" y1="500" x2={nowPoint.x} y2={nowPoint.y} stroke="white" />
      <path d={'M' + offsetPath} className="dial-points-offset" />
      <path d={'M 500,500 L' + offsetPath} className="dial-points-offset-fill" />
      {points.map((angle, idx) => {
        const { x, y } = polarToXY(300, angle);
        return <circle key={idx} r="4" cx={x} cy={y} className="dial-points-point" />;
      })}
    </>
  );
}

export default DialPoints;
